import styled, { css } from 'styled-components';
import { tint } from 'polished';

export const QuestionWrapper = styled.div`
  ${'' /* padding: 0.5rem; */}
  padding: 0.5rem var(--desktop-padding-sizes);
  position: relative;
  .qst-title {
    font-size: 1.25rem;
    font-weight: 500;
    ${'' /* padding: 1rem 0 1.5rem 0; */}
    padding: 0.5rem 0;
    &.placeholder {
      color: var(--muted-strong);
    }
    &.empty {
      padding: 0;
    }
    .required-mark {
      color: var(--red);
      font-size: 1rem;
      font-weight: 600;
      height: 100%;
      vertical-align: text-top;
      margin-left: 2px;
    }
    .upper-label-info {
      color: var(--muted-strong);
      font-size: 0.75rem;
      font-weight: 400;
      height: 100%;
      vertical-align: text-top;
      margin-left: 2px;
    }
  }
  fieldset {
    border-radius: var(--border-radius);
    border: var(--border);
    padding: 1rem 0.75rem;
    ${({ errorState }) =>
      errorState &&
      css`
        border-color: var(--danger);
      `}
  }
  .form-text {
    font-size: smaller;
    display: block;
    margin-top: 0.25rem;
    &.question-helper {
      color: var(--muted-strong);
    }
    &.question-error {
      color: var(--danger);
    }
  }
  .question-labels {
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    color: var(--muted-strong);
    font-size: 0.875rem;
    &.bottom {
      margin-top: 0.5rem;
    }
    &.top {
      margin-bottom: 0.5rem;
    }
    .label {
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    padding: 0.5rem var(--mobile-padding-sizes);
  }

  /** EDITOR SPECIFIC */
  .qst-title-input {
    font-weight: 500;
    height: 2.5rem !important;
  }
  .qst-helper-input {
    font-size: smaller !important;
    display: block !important;
    margin: 0 !important;
    margin-top: 0.25rem !important;
    height: auto !important;
    padding: 0.25rem 0 !important;
    border: none !important;
    border-bottom: 2px solid transparent !important;
    background-color: transparent !important;
    outline: none !important;
    border-radius: 0 !important;
    color: var(--muted-strong) !important;
    width: 66% !important;
    &:hover {
      border-color: var(--muted) !important;
    }
    &:focus {
      border-color: var(--blue) !important;
    }
  }
  /** Select dropdown */
  .question-editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0 0.25rem;
    .question-name {
      height: 1.5rem;
      border: 1px solid var(--btn-background-color-hover);
      border-radius: 4px;
      font-size: 0.75rem;
      display: grid;
      grid-template-columns: 2fr 5fr 1fr;
      align-items: center;
      margin-bottom: -1px;
      .label {
        background-color: var(--btn-background-color-hover);
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 0.25rem;
        color: var(--muted-strong);
      }
      .key-name {
        padding: 0 0.5rem;
        color: var(--muted-strong);
        input {
          color: var(--muted-strong);
          margin: 0;
          padding: 0;
          height: 1rem;
          border: 0;
          background: transparent;
          font-size: 0.75rem;
          &:focus {
            outline: none;
          }
        }
      }
      .help {
        ${'' /* padding: 0.5rem; */}
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        button {
          margin: 0;
          padding: 0 0.25rem;
          border: none;
          background-color: var(--btn-background-color-hover);
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          width: 100%;
          height: 100%;
          color: var(--muted-strong);
          &:focus {
            outline: none;
          }
        }
        .help-tooltip {
          font-size: 0.3rem;
        }
      }
    }
  }
  .question-info-select {
    display: grid;
    grid-template-columns: 66% 1fr;
    align-items: center;
    grid-gap: 0.5rem;
    padding-top: 0.5rem;
    input {
      border-radius: var(--border-radius);
      border: var(--border);
      padding: 0.75rem;
      font-size: 1.1rem;
      color: var(--text-color);
      width: 100%;
      &:focus {
        outline-color: var(--blue);
      }
    }
  }
  .question-type-wrapper {
    .type-dropdown__single-value {
      width: 100%;
    }
    .type-single-option {
      display: grid;
      grid-template-columns: 35% 65%;
      align-items: center;
      ${'' /* font-size: 0.875rem; */}
    }
    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      background-color: var(--white-hover);
      border-radius: 50%;
      width: 26px;
      font-size: 1rem;
      color: white;
      i {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
      &.input {
        color: var(--blue);
        background-color: ${({ theme }) => tint(0.8, theme.colors.blue)};
      }
      &.textarea {
        color: var(--green);
        background-color: ${({ theme }) => tint(0.8, theme.colors.green)};
      }
      &.radio {
        color: var(--orange);
        background-color: ${({ theme }) => tint(0.8, theme.colors.orange)};
      }
      &.checkbox {
        color: var(--yellow);
        background-color: ${({ theme }) => tint(0.8, theme.colors.yellow)};
      }
      &.dropdown {
        color: var(--blue);
        background-color: ${({ theme }) => tint(0.8, theme.colors.blue)};
      }
      &.buttons {
        color: var(--orange);
        background-color: ${({ theme }) => tint(0.8, theme.colors.orange)};
      }
      &.date {
        color: var(--red);
        background-color: ${({ theme }) => tint(0.8, theme.colors.red)};
      }
      &.rating {
        color: var(--yellow);
        background-color: ${({ theme }) => tint(0.8, theme.colors.yellow)};
      }
      &.sentiment {
        color: var(--green);
        background-color: ${({ theme }) => tint(0.8, theme.colors.green)};
      }
      &.slider {
        color: var(--red);
        background-color: ${({ theme }) => tint(0.8, theme.colors.red)};
      }
      &.matrix {
        color: var(--blue);
        background-color: ${({ theme }) => tint(0.8, theme.colors.blue)};
      }
    }
  }

  .type-dropdown__control {
    min-height: auto;
    height: 2.5rem;
    .type-dropdown__indicator-separator {
      display: none;
    }
    .type-dropdown__dropdown-indicator {
      padding: 0 4px;
    }
  }

  .type-dropdown__value-container {
    height: 100%;
    .type-dropdown__input {
      input {
        height: inherit;
      }
    }
  }

  pre {
    font-size: 0.7rem;
    max-width: 430px;
    overflow: scroll;
  }
`;

export const FinishedMessageWrapper = styled(QuestionWrapper)`
  background-color: var(--blue-background-extra-light);
  margin-top: -2.5rem;
  margin-bottom: -6.5rem;
  width: 100%;
  min-height: 350px;
  display: grid;
  grid-gap: 1rem;
  place-content: center;
  text-align: center;
  border-radius: var(--border-radius);
  font-size: 1.25rem;
  font-weight: 400;
  font-family: var(--font-heading);
  padding-top: 2.5rem !important;
  padding-bottom: 4.5rem !important;
  z-index: 1;
  .img-wrapper {
    width: 100%;
    min-width: 300px;
    height: 300px;
    position: relative;
    img {
      object-fit: contain;
      opacity: 0.7;
      z-index: -1;
    }
    @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
      min-width: 250px;
      height: 250px;
    }
  }
`;

export const QuestionEditorElementWrapper = styled.div`
  position: relative;
  transition: box-shadow 0.15s;
  .grab-icon {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    padding: 1rem;
    cursor: move;
    color: var(--muted-strong);
    opacity: 0;
  }
  ${({ hovered }) =>
    hovered &&
    css`
      background-color: var(--white-hover);
      .grab-icon {
        opacity: 1;
      }
    `}
  ${({ isDragging }) =>
    isDragging &&
    css`
      background-color: var(--white);
      box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.1);
    `}

  .select-feedback {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 0.5rem;
    background-color: var(--blue);
  }
`;

export const InputWrapper = styled(QuestionWrapper)`
  --input-height: var(--item-height);
  --textarea-height: calc(2.5 * var(--input-height));

  &.input-question {
    width: 100%;
    input,
    textarea {
      display: block;
      width: 100%;
      margin: 0 auto;
      border-radius: var(--border-radius);
      border: var(--border);
      padding: 0.75rem;
      font-size: 1.1rem;
      color: var(--text-color);
      ${({ errorState }) =>
        errorState &&
        css`
          border-color: var(--danger) !important;
        `}
    }
    input {
      height: var(--input-height);
    }
    textarea {
      height: var(--textarea-height);
      resize: none;
    }
    .qst-hidden-input {
      margin-top: 0.5rem;
    }
  }

  .options-header {
    padding: 0.875rem 0;
    .options-label {
      font-weight: 500;
      padding-bottom: 0.25rem;
      display: grid;
      grid-template-columns: 5fr 1fr 1fr;
      align-items: center;
    }
    .options-desc {
      color: var(--muted-strong);
      font-size: smaller;
      display: flex;
    }
    .help {
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      padding-left: 3px;

      button {
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        color: var(--muted-strong);
        &:focus {
          outline: none;
        }
      }
      .help-tooltip {
        font-size: 0.3rem;
      }
    }
  }

  .inputtype-opt {
    .inputtype-check-wrapper {
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.25rem;
      [data-reach-custom-checkbox-container] {
        width: 1.25rem;
        height: 1.25rem;
        span {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
    label {
      margin: 0;
      ${'' /* justify-content: flex-end; */}
    }
  }
`;

export const MDQuestionWrapper = styled(InputWrapper)`
  .qst-md-input {
    margin-top: 1rem !important;
    height: calc(3.5 * var(--input-height)) !important;
    font-size: 1rem !important;
    line-height: 1.15rem !important;
  }
  .md-text-wrapper {
    h1 {
      font-size: 2.25rem;
      margin: 0.5rem 0;
    }
    h2 {
      font-size: 1.875rem;
      margin: 0.5rem 0;
    }
    h3 {
      font-size: 1.5rem;
      margin: 0.5rem 0;
    }
    h4 {
      font-size: 1.25rem;
      margin: 0.5rem 0;
    }
    p,
    ul,
    ol {
      font-size: 1rem !important;
      line-height: 1.15rem;
      word-break: break-word;
    }
    a {
      color: var(--blue);
      &:hover {
        color: var(--blue-hover);
      }
    }
    hr {
      width: 80%;
      border: 1px solid var(--muted);
      margin: 2rem auto;
    }
    .img-wrapper {
      text-align: center;
      display: block;
      width: 100%;
      img {
        max-width: 100%;
      }
    }
    @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
      h1 {
        font-size: 1.875rem;
        margin: 0.5rem 0;
      }
      h2 {
        font-size: 1.5rem;
        margin: 0.5rem 0;
      }
      h3 {
        font-size: 1.25rem;
        margin: 0.5rem 0;
      }
      h4 {
        font-size: 1.1rem;
        margin: 0.5rem 0;
      }

      ul,
      ol {
        padding-inline-start: 2rem;
      }
      hr {
        width: 80%;
        border: 1px solid var(--muted);
        margin: 1.5rem auto;
      }
    }
  }
`;

export const DateWrapper = styled(QuestionWrapper)`
  &.date-question {
    width: 100%;
    .date-wrapper {
      width: 100%;
      position: relative;
      .cal-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 100%;
        color: var(--black);
        z-index: -1;
        ${'' /* background-color: grey; */}
        i {
          height: inherit;
          transform: scale(0.4);
        }
      }
      /** if inline */
      &.inline {
        text-align: center;
        padding-top: 0.5rem;
      }
    }
    .input-button {
      display: block;
      width: 100%;
      margin: 0 auto;
      border-radius: var(--border-radius);
      border: var(--border);
      background-color: transparent;
      padding: 0.75rem;
      font-size: 1.1rem;
      color: var(--text-color);
      ${({ errorState }) =>
        errorState &&
        css`
          border-color: var(--danger);
        `}
      &:disabled {
        background-color: var(--btn-background-color-hover);
        border-color: var(--btn-background-color-hover);
      }
    }
    .input-button {
      height: var(--item-height);
      text-align: inherit;
      font-weight: inherit;
    }
    /** datepicker overrides */
    .react-datepicker {
      font-family: inherit;
      border: var(--border);
      ${({ errorState }) =>
        errorState &&
        css`
          border-color: var(--danger);
        `}
    }
    .react-datepicker__header {
      background-color: var(--white-hover);
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker-popper {
      z-index: 20;
    }
    /** button selected colors */
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: var(--blue);
    }
    .react-datepicker__time-list-item--selected {
      background-color: var(--blue) !important;
    }
  }
`;

export const SelectWrapper = styled(QuestionWrapper)`
  ${'' /* Select border */}
  .select-dropdown__control {
    border: var(--border);
    height: var(--item-height);
    ${({ errorState }) =>
      errorState &&
      css`
        border-color: var(--danger);
      `}
  }
  .select-dropdown__menu {
    z-index: 20;
  }
`;

export const RadioWrapper = styled(QuestionWrapper)`
  &.radio-question {
    width: 100%;
    .radio-list {
      border: 1px solid transparent;
      display: grid;
      grid-template-columns: repeat(${({ cols }) => cols && cols}, 1fr);
      grid-gap: 0.25rem;
      ${({ errorState }) =>
        errorState &&
        css`
          border-radius: var(--border-radius);
          border: var(--border);
          border-color: var(--danger);
        `}
    }
    .radio-button {
      --radio-item-height: 2rem;
      label {
        display: block;
        position: relative;
        padding-left: 2.85rem;
        margin-bottom: 0.25rem;
        cursor: pointer;
        user-select: none;
        height: 2.5rem;
        display: flex;
        align-items: center;
        border: 1px solid transparent;
        border-radius: var(--border-radius);
        transition: 0.2s all;
        /* Hide the browser's default radio button */
        input {
          position: absolute;
          opacity: 0;
        }
        /* Create a custom radio button */
        .checkmark {
          position: absolute;
          top: 0.2rem;
          left: 0.25rem;
          height: var(--radio-item-height);
          width: var(--radio-item-height);
          background-color: var(--btn-background-color);
          border-radius: 50%;
          /* Create the indicator (the dot/circle - hidden when not checked) */
          &:after {
            content: '';
            position: absolute;
            display: none;
          }
          &:after {
            border-radius: 50%;
            background: white;
            height: 100%;
            width: 100%;
            transform: scale(0.45);
          }
        }
        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
          background-color: var(--btn-background-color-hover);
        }
        /* When the radio button is checked, add a blue background */
        input:checked ~ .checkmark {
          background-color: var(--blue);
        }
        /* Show the indicator (dot/circle) when checked */
        input:checked ~ .checkmark:after {
          display: block;
        }
        &[aria-disabled='true'] {
          cursor: auto;
        }
        &:hover {
          border: var(--border);
          background-color: var(--white-hover);
          span[aria-checked='true'],
          span[data-checked='true'] {
            background-color: var(--blue-hover);
          }
        }
        &[data-checked='true'] {
          color: var(--blue);
          background-color: ${({ theme }) => tint(0.8, theme.colors.blue)};
          border-color: ${({ theme }) => tint(0.4, theme.colors.blue)};
          &:hover {
            background-color: ${({ theme }) => tint(0.85, theme.colors.blue)};
          }
        }
      }
    }
  }
`;

export const CheckboxWrapper = styled(QuestionWrapper)`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    display: grid;
    grid-template-columns: repeat(${({ cols }) => cols && cols}, 1fr);
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
    ${({ errorState }) =>
      errorState &&
      css`
        border-radius: var(--border-radius);
        border: var(--border);
        border-color: var(--danger);
      `}

    /* Hide the browser's default radio button */
  input.pollme-checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &[aria-disabled='true'] {
        cursor: auto;
      }
    }
  }
  li {
    ${'' /* height: var(--item-height); */}
    min-height: 2.85rem;
  }

  --checkbox-item-height: 1.75rem;

  label {
    ${'' /* margin-bottom: 0.25rem; */}
    padding: 0rem 0.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all;
    display: grid;
    grid-template-columns: 36px 1fr;
    align-items: center;
    text-align: left;
    grid-gap: 0.25rem;
    transition: 0.2s all;
    .pollme-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .checkbox-label {
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
        font-size: 0.85rem;
        padding: 0.2rem 0;
      }
    }
    &:hover {
      [data-reach-custom-checkbox-container] {
        &[data-state='unchecked'] {
          background-color: var(--btn-background-color-hover);
        }
        &[data-state='checked'] {
          background-color: var(--blue-hover);
        }
      }
      &[aria-disabled='true'] {
        cursor: auto;
      }
    }
    [data-reach-custom-checkbox-container]:focus-within,
    [data-reach-custom-checkbox-container][data-focus] {
      outline: none;
    }
    &[aria-disabled='true'] {
      cursor: auto;
    }
    &.checkbox-checkall {
      border: 1px solid transparent;
      border-radius: var(--border-radius);
      padding-bottom: 0.5rem;
    }
    &.checkbox-option {
      height: 100%;
      border: 1px solid transparent;
      border-radius: var(--border-radius);
      &:hover {
        border: var(--border);
        background-color: var(--white-hover);
      }
    }
    &[data-checked='true'] {
      color: var(--blue);
      background-color: ${({ theme }) => tint(0.8, theme.colors.blue)};
      border-color: ${({ theme }) => tint(0.4, theme.colors.blue)};
      &:hover {
        background-color: ${({ theme }) => tint(0.85, theme.colors.blue)};
      }
    }
  }

  [data-reach-custom-checkbox-container] {
    background-color: var(--btn-background-color);
    border-radius: var(--border-radius);
    height: var(--checkbox-item-height);
    width: var(--checkbox-item-height);
    box-sizing: initial;
    ${'' /* margin-right: 0.5rem;
    margin-left: 0.25rem; */}
    margin: 0 auto;
    input {
      cursor: pointer;
      &:disabled {
        cursor: auto;
      }
    }
    &:hover {
      background-color: var(--btn-background-color-hover);
    }
    &[aria-disabled='true'] {
      cursor: auto;
      background-color: var(--btn-background-color-hover);
    }
    &[data-state='checked'] {
      background-color: var(--blue);
    }
    span {
      display: block;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      height: var(--checkbox-item-height);
      width: var(--checkbox-item-height);
      border-radius: var(--border-radius);
      z-index: 0;
      background: transparent;
      transform: scale(0.45);
      &[aria-disabled='true'] {
        cursor: auto;
      }
      &[aria-checked='true'],
      &[data-checked='true'] {
        background-color: var(--white);
      }
      &[aria-checked='mixed'],
      &[data-checked='mixed'] {
        background-color: var(--muted-strong);
        transform: scaleX(0.5) scaleY(0.2);
      }
    }
  }

  .options-header {
    padding: 0.875rem 0;
    .options-label {
      font-weight: 500;
      padding-bottom: 0.25rem;
      display: grid;
      grid-template-columns: 5fr 2fr;
      align-items: center;
      @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
        grid-template-columns: 3fr 2fr;
      }
      .cols-label {
        color: var(--muted-strong);
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        .help {
          ${'' /* padding: 0.5rem; */}
          height: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          padding-left: 3px;

          button {
            border: none;
            background: none;
            margin: 0;
            padding: 0;
            color: var(--muted-strong);
            &:focus {
              outline: none;
            }
          }
          .help-tooltip {
            font-size: 0.3rem;
          }
        }
      }
    }
    .options-desc {
      color: var(--muted-strong);
      font-size: smaller;
      display: flex;
    }
    .help {
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      padding-left: 3px;

      button {
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        color: var(--muted-strong);
        &:focus {
          outline: none;
        }
      }
      .help-tooltip {
        font-size: 0.3rem;
      }
    }
  }
  .selectAll-opt {
    .selectAll-check-wrapper {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.25rem;
      [data-reach-custom-checkbox-container] {
        width: 1.25rem;
        height: 1.25rem;
        span {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
    label {
      margin: 0;
      color: var(--muted-strong);
      font-size: 0.875rem;
      display: flex;
      justify-content: center;
      ${'' /* justify-content: flex-end; */}
    }
  }
`;

export const SliderWrapper = styled(QuestionWrapper)`
  :root {
    --reach-slider: 1;
  }

  &.slider-question {
    [data-reach-slider-input],
    .question-labels.top,
    .question-labels.bottom {
      padding: 0 1rem;
    }
  }

  [data-reach-slider-input] {
    max-width: 100%;
    ${'' /* max-width: 90%; */}
    margin: 0 auto;
  }

  [data-reach-slider-input][data-orientation='horizontal'] {
    height: 0.5rem;
  }

  [data-reach-slider-input][data-orientation='vertical'] {
    width: 0.5rem;
    /* the height is somewhat arbitrary but necessary for vertical sliders for
  basic functionality */
    height: 250px;
    max-height: 100%;
  }

  [data-reach-slider-input][data-disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  [data-reach-slider-track] {
    position: relative;
    border-radius: 0.25rem;
    background: var(--btn-background-color);
  }

  [data-reach-slider-track][data-orientation='horizontal'] {
    width: 100%;
    height: inherit;
  }

  [data-reach-slider-track][data-orientation='vertical'] {
    width: inherit;
    height: 100%;
  }

  /* This pseudo element provides an invisible area that increases the touch
target size of the track */
  [data-reach-slider-track]::before {
    content: '';
    position: absolute;
  }

  [data-reach-slider-track][data-orientation='horizontal']::before {
    width: 100%;
    height: 1.5rem;
    top: calc(-0.5rem - 1px);
    left: 0;
  }

  [data-reach-slider-track][data-orientation='vertical']::before {
    width: 1.5rem;
    height: 100%;
    top: 0;
    left: calc(-0.5rem - 1px);
  }

  [data-reach-slider-handle] {
    width: 1.5rem;
    height: 1.5rem;
    background: var(--blue);
    border-radius: 50%;
    z-index: 1;
    transform-origin: center;
    cursor: ew-resize; /** grab, or ns-resize for vertical  */
    &:hover {
      background: var(--blue-hover);
    }
  }

  [data-reach-slider-handle][aria-orientation='horizontal'] {
    top: 50%;
    transform: translateY(-50%);
  }

  [data-reach-slider-handle][aria-orientation='horizontal']:focus {
    transform: translateY(-50%);
  }

  [data-reach-slider-handle][aria-orientation='vertical'] {
    left: 50%;
    transform: translateX(-50%);
  }

  [data-reach-slider-range] {
    border-radius: inherit;
    background: var(--blue);
    left: 0;
    bottom: 0;
  }

  [data-reach-slider-range][data-orientation='horizontal'] {
    height: 100%;
  }

  [data-reach-slider-range][data-orientation='vertical'] {
    width: 100%;
  }

  [data-reach-slider-marker] {
    background: var(--muted);
    transform-origin: center;
  }

  [data-reach-slider-marker][data-orientation='horizontal'] {
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3px;
    height: 0.75rem;
  }

  [data-reach-slider-marker][data-orientation='vertical'] {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.75rem;
    height: 3px;
  }

  [data-reach-slider-marker][data-state='at-value'],
  [data-reach-slider-marker][data-state='under-value'] {
    /* */
  }

  /* TODO: Remove in 1.0 */
  [data-reach-slider-track-highlight] {
    border-radius: inherit;
    background: var(--blue);
    left: 0;
    bottom: 0;
  }

  [data-reach-slider-track-highlight][data-orientation='horizontal'] {
    height: 100%;
  }

  [data-reach-slider-track-highlight][data-orientation='vertical'] {
    width: 100%;
  }
`;

export const RatingWrapper = styled(QuestionWrapper)`
  .options-header {
    padding: 0.875rem 0;
    .options-label {
      font-weight: 500;
      padding-bottom: 0.25rem;
      display: grid;
      grid-template-columns: 4fr 2fr 2fr;
      align-items: center;
      .range-label {
        color: var(--muted-strong);
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        .help {
          ${'' /* padding: 0.5rem; */}
          height: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          padding-left: 3px;

          button {
            border: none;
            background: none;
            margin: 0;
            padding: 0;
            color: var(--muted-strong);
            &:focus {
              outline: none;
            }
          }
          .help-tooltip {
            font-size: 0.3rem;
          }
        }
      }
    }
    .options-desc {
      color: var(--muted-strong);
      font-size: smaller;
    }
    .range-number-wrapper {
      .range-dropdown {
        font-size: 0.8rem;

        .range-dropdown__control {
          min-height: auto;
          height: 1.5rem;
          border: none;
          .range-dropdown__indicator-separator {
            display: none;
          }
          .range-dropdown__dropdown-indicator {
            padding: 0 4px;
          }
        }

        .cols-dropdown__value-container {
          height: 100%;
          .range-dropdown__input {
            input {
              height: inherit;
            }
          }
        }
      }
    }
  }

  .variant-opt {
    .variant-check-wrapper {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.25rem;
      [data-reach-custom-checkbox-container] {
        width: 1.35rem;
        height: 1.35rem;
        border-radius: 50%;
        span {
          width: 1.35rem;
          height: 1.35rem;
          border-radius: 50%;
        }
      }
      .star-item {
        padding-left: 0.5rem;
        color: var(--yellow);
        display: flex !important;
      }
      .heart-item {
        padding-left: 0.5rem;
        color: var(--danger);
        display: flex !important;
        transform: scale(0.9);
      }
    }
    label {
      margin: 0;
      ${'' /* justify-content: flex-end; */}
    }
  }
`;

export const RatingGroup = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 0.2rem;
  margin-top: 0.5rem;
  border: 1px solid transparent;
  ${({ errorState }) =>
    errorState &&
    css`
      border-radius: var(--border-radius);
      border: var(--border);
      border-color: var(--danger);
    `}

  button {
    display: block;
    margin: 0px;
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: left;
    font: inherit;
    position: relative;
    z-index: 3;
    padding: 8px;
    border-radius: 50%;
    transition: 0.1s all;
    &:hover {
      transform: scale(1.05);
    }
    /** prevent outline when hover, to se animation */
    &[aria-checked='true'],
    &[data-checked='true'],
    &.checked {
      &:focus {
        outline: 0;
      }
    }
  }

  .rating-item-star {
    &:focus {
      border: 2px solid var(--yellow);
      outline: 2px solid var(--yellow);
    }
    cursor: pointer;
    color: var(--btn-background-color);
    transition: 0.2s all;
    &[aria-checked='true'],
    &[data-checked='true'],
    &.checked {
      color: var(--yellow);
    }
    &[data-focused='true'] {
      svg {
        path {
          stroke: var(--blue);
          stroke-width: 100;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      }
    }
    &:hover {
      color: var(--yellow-hover);
      &[aria-disabled='true'] {
        color: var(--btn-background-color-hover);
      }
    }
  }
  .rating-item-heart {
    transform: scale(0.9);
    &:focus {
      border: 1px solid var(--danger);
      outline: 1px solid var(--danger);
    }
    cursor: pointer;
    color: var(--btn-background-color);
    transition: 0.2s all;
    &[aria-checked='true'],
    &[data-checked='true'],
    &.checked {
      color: var(--danger);
    }
    &[data-focused='true'] {
      svg {
        overflow: initial;
        path {
          stroke: var(--blue);
          stroke-width: 100;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      }
    }
    &:hover {
      color: var(--danger-hover);
      &[aria-disabled='true'] {
        color: var(--btn-background-color-hover);
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    ${({ isStackableOnMobile }) =>
      isStackableOnMobile &&
      css`
        button {
          svg {
            width: 42px;
            height: 42px;
          }
        }
      `}
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    ${({ isStackableOnMobile }) =>
      isStackableOnMobile &&
      css`
        display: grid;
        button {
          svg {
            width: 56px;
            height: 56px;
          }
        }
      `}
  }
`;

export const SentimentWrapper = styled(QuestionWrapper)`
  font-weight: 500;
  .options-header {
    padding: 0.875rem 0;
    .options-label {
      font-weight: 500;
      padding-bottom: 0.25rem;
      display: grid;
      grid-template-columns: 5fr 1fr 1fr;
      align-items: center;
    }
    .options-desc {
      color: var(--muted-strong);
      font-size: smaller;
      display: flex;
    }
    .help {
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      padding-left: 3px;

      button {
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        color: var(--muted-strong);
        &:focus {
          outline: none;
        }
      }
      .help-tooltip {
        font-size: 0.3rem;
      }
    }
  }

  .variant-opt {
    .variant-check-wrapper {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.25rem;
      [data-reach-custom-checkbox-container] {
        width: 1.35rem;
        height: 1.35rem;
        border-radius: 50%;
        span {
          width: 1.35rem;
          height: 1.35rem;
          border-radius: 50%;
        }
      }
    }
    label {
      margin: 0;
      ${'' /* justify-content: flex-end; */}
    }
  }
`;

export const SentimentGroup = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: ${({ variant }) =>
    variant === 'complete' ? 'repeat(5, 1fr)' : 'repeat(3, 1fr)'};
  border: 1px solid transparent;
  margin-top: 0.5rem;
  ${({ errorState }) =>
    errorState &&
    css`
      border-radius: var(--border-radius);
      border: var(--border);
      border-color: var(--danger);
    `}

  @media(max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    grid-gap: 0.25rem;
  }
`;
export const SentimentItem = styled.button`
  width: 100%;
  min-height: var(--item-height);
  font-size: 1rem;
  font-weight: 500;
  border-radius: var(--border-radius);;
  background-color: var(--white);
  color: color: var(--btn-text-color);
  transition: 0.2s all;
  border: var(--border);
  border-color: transparent;
  ${'' /* border: none; */}
  padding: 0.5rem 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .emoji-wrapper {
    width: 64px;
    height: 64px;
    position: relative;
    img {
      object-fit: contain;
    }
  }
  &:hover {
    ${'' /* background-color: var(--btn-background-color-hover); */}
    ${'' /* border-color: var(--btn-background-color-hover); */}
    transform: translateY(-2px);
    border: var(--border);
    background-color: var(--white-hover);
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
  }
  &:disabled {
    background-color: var(--btn-background-color);
    transform: translateY(0px);
    box-shadow: none;
  }
  &[aria-checked='true'],
  &[data-checked='true'],
  &.checked {
    ${'' /* color: var(--white);
    background-color: var(--blue);
    border-color: var(--blue); */}
    color: var(--blue);
    background-color: ${({ theme }) => tint(0.92, theme.colors.blue)};
    border-color: ${({ theme }) => tint(0.4, theme.colors.blue)};
    ${'' /* transform: translateY(-1px); */}
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
    &:hover {
      ${'' /* background-color: var(--blue-hover); */}
      background-color: ${({ theme }) => tint(0.95, theme.colors.blue)};
    }
    &:focus {
      outline: none;
    }
  }
  @media(max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}){
    padding: 0.25rem 0.2rem;
    .emoji-wrapper {
      width: 44px;
      height: 44px;
      position: relative;
      img {
        object-fit: contain;
      }
    }
    svg {
      width: 44px;
    }
  }
`;

export const ButtonsWrapper = styled(QuestionWrapper)`
  .button-option {
    width: 100%;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: var(--border-radius);
    background-color: var(--btn-background-color);
    color: color: var(--btn-text-color);
    transition: 0.2s all;
    border: none;
    padding: 0.5rem 0.25rem;
    height: var(--item-height);
    &:hover {
      background-color: var(--btn-background-color-hover);
      border-color: var(--btn-background-color-hover);
      transform: translateY(-2px);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
    &:focus {
      outline-color: var(--blue);
    }
    &:disabled {
      transform: translateY(0px);
      box-shadow: none;
    }
    &[aria-checked='true'],
    &[data-checked='true'],
    &.checked {
      background-color: var(--blue);
      border-color: var(--blue);
      color: var(--white);
      ${'' /* transform: translateY(-1px); */}
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      &:hover {
        background-color: var(--blue-hover);
      }
      &:focus {
        outline: none;
      }
    }
  }
`;

export const ButtonsGroup = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(${({ cols }) => cols && cols}, 1fr);
  border: 1px solid transparent;
  margin-top: 0.5rem;
  ${({ errorState }) =>
    errorState &&
    css`
      border-radius: var(--border-radius);
      border: var(--border);
      border-color: var(--danger);
    `}
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    ${({ isStackableOnMobile }) =>
      isStackableOnMobile &&
      css`
        grid-template-columns: repeat(1, 1fr);
      `}
  }
`;

export const RadioButtonsWrapper = styled(QuestionWrapper)`
  .radio-option {
    width: 100%;
    background-color: var(--white);
    color: var(--btn-text-color);
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    padding: 0.5rem 0.25rem;
    height: var(--item-height);
    display: grid;
    grid-template-columns: 36px 1fr;
    align-items: center;
    text-align: left;
    grid-gap: 0.25rem;
    transition: 0.2s all;
    &:focus {
      outline-color: var(--blue);
    }
    &:disabled {
      box-shadow: none;
    }
    .radio-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .radio-label {
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
      opacity: 0.6;
    }
    &:hover {
      border: var(--border);
      background-color: var(--white-hover);
      .radio-label {
        opacity: 1;
      }
      span[aria-checked='true'],
      span[data-checked='true'] {
        background-color: var(--blue-hover);
      }
    }
    &[aria-checked='true'],
    &[data-checked='true'],
    &.checked {
      color: var(--blue);
      background-color: ${({ theme }) => tint(0.8, theme.colors.blue)};
      border-color: ${({ theme }) => tint(0.4, theme.colors.blue)};
      .radio-label {
        opacity: 1;
      }
      &:hover {
        background-color: ${({ theme }) => tint(0.85, theme.colors.blue)};
      }
      &:focus {
        outline: none;
      }
    }
  }
`;

export const MatrixWrapper = styled(QuestionWrapper)`
  .matrix-option {
    width: 100%;
    background-color: transparent;
    color: var(--btn-text-color);
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    padding: 0.25rem 0.25rem;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    text-align: center;
    grid-gap: 0.25rem;
    transition: 0.2s all;
    &:focus {
      outline-color: var(--blue);
    }
    &:disabled {
      box-shadow: none;
    }
    i {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
      border: var(--border);
      background-color: var(--white-hover);
      span[aria-checked='true'],
      span[data-checked='true'] {
        background-color: var(--blue-hover);
      }
    }

    &[aria-checked='true'],
    &[data-checked='true'],
    &.checked {
      color: var(--blue);
      background-color: ${({ theme }) => tint(0.8, theme.colors.blue)};
      border-color: ${({ theme }) => tint(0.4, theme.colors.blue)};
      &:hover {
        background-color: ${({ theme }) => tint(0.85, theme.colors.blue)};
      }
      &:focus {
        outline: none;
      }
    }
  }
`;

export const MatrixItems = styled.div`
  border: 1px solid transparent;
  ${({ errorState }) =>
    errorState &&
    css`
      border-radius: var(--border-radius);
      border: var(--border);
      border-color: var(--danger);
    `}
`;

export const MatrixGroup = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  padding: 0.25rem;
  transition: 0.1s all;
  margin-top: 0.5rem;
  &.heading-row {
    position: sticky;
    ${'' /* top: 0; */}
    top: 40px; /** to account for sticky header */
    ${'' /* background-color: var(--white); */}
    z-index: 1;
    @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
      display: none;
    }
  }
  &.item-row {
    &:hover {
      background-color: var(--muted);
    }
    ${({ rowError }) =>
      rowError &&
      css`
        background-color: var(--danger-background);
      `}
  }
  .column-label {
    width: 100%;
    text-align: center;
    word-break: break-word;
    font-size: 0.65rem;
    letter-spacing: -0.25px;
    font-weight: 500;
    color: var(--muted-strong);
  }
  .row-label {
    color: var(--btn-text-color);
    font-size: 1rem;
    padding-left: 0.25rem;
  }
  .matrix-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.5rem;
    &.item-row {
      &:hover {
        background-color: var(--white-hover);
      }
    }
    .row-label {
      color: var(--btn-text-color);
      font-size: 1.15rem;
      padding-left: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.25rem;
    }
  }
`;

export const MatrixOptions = styled.div`
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(${({ cols }) => cols && cols}, 1fr);
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  .radio-icon {
    display: initial;
  }
  .radio-opt {
    display: none;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    grid-template-columns: repeat(1, 1fr);
    .matrix-option {
      border: var(--border);
    }
    .radio-opt {
      display: initial;
      padding: 0.5rem 0;
    }
    .radio-icon {
      display: none;
    }
  }
`;

export const QuestionOptionsEditorStyles = styled.div`
  .options-header {
    padding: 0.875rem 0;
    .options-label {
      font-weight: 500;
      padding-bottom: 0.25rem;
      display: grid;
      grid-template-columns: 5fr 1fr 1fr;
      align-items: center;
      @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
        grid-template-columns: 3fr 1fr 1fr;
      }
      .cols-label {
        color: var(--muted-strong);
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        .help {
          ${'' /* padding: 0.5rem; */}
          height: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          padding-left: 3px;

          button {
            border: none;
            background: none;
            margin: 0;
            padding: 0;
            color: var(--muted-strong);
            &:focus {
              outline: none;
            }
          }
          .help-tooltip {
            font-size: 0.3rem;
          }
        }
      }
    }
    .options-desc {
      color: var(--muted-strong);
      font-size: smaller;
    }
    .cols-number-wrapper {
      .cols-dropdown {
        font-size: 0.8rem;

        .cols-dropdown__control {
          min-height: auto;
          height: 1.5rem;
          border: none;
          .cols-dropdown__indicator-separator {
            display: none;
          }
          .cols-dropdown__dropdown-indicator {
            padding: 0 4px;
          }
        }

        .cols-dropdown__value-container {
          height: 100%;
          .cols-dropdown__input {
            input {
              height: inherit;
            }
          }
        }
      }
    }
  }

  .option-list-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 4px;
    padding: 0.5rem 0;
    &.dragging {
      border: 2px dashed var(--muted-strong);
    }
  }
  .option-row {
    display: grid;
    grid-template-columns: 16px 5fr 1fr;
    grid-gap: 0.25rem;
    align-items: end;
    border: 1px solid transparent;
    &.dragging {
      background-color: var(--white);
      border: 1px dashed var(--muted-strong);
    }
    .opt-grabber {
      cursor: move;
      color: var(--muted-strong);
      transform: rotate(90deg);
      opacity: 1;
    }
    .opt-input {
      height: 100%;
      input {
        border: none;
        outline: none;
        height: 100%;
        padding: 0 0.25rem;
        border-bottom: 2px solid transparent;
        width: 100%;
        background-color: transparent;
        &:hover {
          border-color: var(--muted);
        }
        &:focus {
          border-color: var(--blue);
        }
      }
    }
    .opt-actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
      grid-gap: 0.25rem;
      align-items: center;
      justify-content: center;
      button {
        i {
          display: flex !important;
        }
      }
    }
    &:hover {
      .opt-grabber {
        opacity: 1;
      }
    }
    button {
      border: none;
      color: var(--muted-strong);
      background-color: var(--btn-background-color);
      border-radius: 50%;
      padding: 0.25rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        background-color: var(--btn-background-color-hover);
      }
      :focus {
        outline: none;
      }
      :disabled {
        color: var(--muted);
        background-color: var(--btn-background-color);
      }
    }
  }
`;

export const QuestionSettingsDrawerStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0;
  border-top: 1px solid var(--muted);
  margin-top: 2rem;
  .options-actions {
    display: grid;
    grid-template-columns: 40px 40px 40px 100px;
    ${({ isPlainText }) =>
      isPlainText &&
      css`
        grid-template-columns: 40px 40px;
      `}
    grid-gap: 0.5rem;
    text-align: center;
    align-items: center;
    button {
      border: none;
      color: var(--btn-text-color);
      background-color: var(--white-hover);
      border-radius: 8px;
      padding: 0.5rem 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      :hover {
        background-color: var(--btn-background-color-hover);
      }
      :focus {
        outline: none;
      }
      &[data-active='true'] {
        background-color: var(--blue);
        color: var(--white);
      }
    }
    .required-opt {
      border-left: 1px solid var(--muted);
      .required-check-wrapper {
        padding: 0;
        [data-reach-custom-checkbox-container] {
          width: 1.5rem;
          height: 1.5rem;
          span {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
      label {
        margin: 0;
        justify-content: flex-end;
      }
    }
  }
`;

export const QuestionValidationOptionStyles = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  /** Select dropdown */
  .question-validation-wrapper {
    width: 50%;
  }
  .validation-dropdown__control {
    border: none;
  }
  .validation-dropdown__value-container {
    height: 1rem;
  }
`;

export const QuestionReportWrapper = styled.div`
  .question-tile {
    font-size: 1.5rem;
    ${'' /* font-weight: 500; */}
    padding: 0.875rem 0;
    text-align: center;
  }
  .report-disabled {
    text-align: center;
    padding: 3rem 1.5rem;
    background-color: var(--white-hover);
    border-radius: var(--border-radius);
    color: var(--muted-strong);
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  .chart-wrapper {
    position: relative;
    padding: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 250px;
    button.toggle-type {
      z-index: 1;
      position: absolute;
      right: 0.5rem;
      top: 0.8rem;
      font-size: 0.875rem;
      border: none;
      ${'' /* background-color: var(--white); */}
      outline: none;
      border-radius: 4px;
      color: var(--btn-text-color);
      padding: 0.5rem 0.5rem;
      background-color: var(--white-hover);
      &:hover {
        background-color: var(--btn-background-color);
      }
    }
  }
`;

export default QuestionWrapper;
